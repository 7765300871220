.social {
  position: relative;
  z-index: 20;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transform: scale(1);
}

.social a {
  margin-left: 9px;
  margin-right: 9px;
  transition: all 0.2s ease-out;
}

.social a:hover {
  transform: scale(1.2);
}

.social a:active {
  transform: scale(1);
  opacity: 0.6;
}

.social svg {
  width: 30px;
}
