.header {
  /* height: 100vh; */
  position: relative;
  z-index: 20;

  padding-top: 40px;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 40px;
  box-sizing: border-box;
  text-align: center;
  transition: all 0.3s;
  animation: 1s 1s fade backwards ease-out;
}

.header img,
.header svg {
  /* max-width: 666px; */
  max-width: 420px;
  width: 85%;
  height: auto;
}

.header__info {
  max-width: 666px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  text-decoration: none;
  color: white;
  text-align: center;
  font-family: 'Museo Sans', 'Helvetica', sans-serif;
  font-weight: 900;
  font-size: 20px;
}

.header a {
  text-decoration: underline;
  /* text-decoration-color: var(--red-color); */
  color: white;
  text-align: center;
  font-family: 'Museo Sans', 'Helvetica', sans-serif;
  font-weight: 900;
  font-size: 14px;
  font-style: normal;
  transition: all 0.2s ease-out;
}

.header a:active {
  opacity: 0.5;
}

.header__tour {
  color: white;
  text-align: center;
  font-family: 'Museo Sans', 'Helvetica', sans-serif;
  font-weight: 900;
  font-size: 20px;
}

@media (hover: hover) {
  .header a:hover {
    color: grey;
    color: var(--red-color);
  }
}
