.list {
  position: relative;
  z-index: 20;
  box-sizing: border-box;
  margin: 0 auto;
  /* padding-left: 10%; */
  /* padding-right: 10%; */
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
  max-width: 420px;
  font-family: 'Museo Sans', 'Helvetica', sans-serif;
  font-weight: 300;
  font-size: 19px;
  animation: 1s 1.5s fade backwards ease-out;
}

.list__item {
  margin-bottom: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.3;
}



.list__partone {

}

.list__parttwo {

}




/* .list__date {
  font-weight: 900;
} */

/* .list__city {
  font-weight: 900;
}

.list__place {
  font-weight: 300;
} */

/* .list__project {
  font-weight: 900;
} */




.list__btns {
  /* display: flex; */
  /* align-items: center; */
  /* width: 100%; */
}

.list__btns > div {
  /* width: 49%; */
}

.list__btn {
  box-sizing: border-box;
  display: block;
  /* width: 49%; */
  width: 100%;
  padding: 12px;
  padding-left: 8px;
  padding-right: 8px;
  /* background: white; */
  background: transparent;
  color: black;
  color: white;
  /* border: 2px solid grey; */
  border: 1px solid white;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0;
  font-family: 'Museo Sans', 'Helvetica', sans-serif;
  font-size: 16px;
  font-family: inherit;
  font-weight: 700;
  text-decoration: none;
  text-shadow: none;
  box-shadow: none;
  transition: all 0.5s;
}

.list__btn--disabled {
  background-color: grey;
}

.list__btn--tickets {
  margin-bottom: 10px;
}




@media (min-width: 768px) {
  .list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (hover: hover) {
  .list__btn:hover {
    background-color: black;
    background-color: var(--red-color);
    color: white;
  }

  .list__btn--disabled:hover {
    background-color: grey;
    color: black;
    cursor: not-allowed;
  }
}


/*
.list__btn--alt {
  color: black !important;
  border: 2px solid black !important;
  background-color: var(--pink-main) !important;
}

.list__btn:hover {
  color: black !important;
  border: 2px solid var(--pink-main) !important;
  background-color: var(--pink-main) !important;
  opacity: 1 !important;
}

.list__btn--alt:hover {
  color: var(--pink-main) !important;
  border: 2px solid black !important;
  background-color: black !important;
  opacity: 1 !important;
}

.list__btn:active {
  opacity: 0.7 !important;
}

.list__btn--disabled {
  cursor: not-allowed !important;
}

.list__btn--disabled,
.list__btn--disabled:active,
.list__btn--disabled:hover {
  color: white !important;
  opacity: 0.2 !important;
  border: 2px solid var(--pink-main) !important;
  background: transparent !important;
}

.list__btn--margin {
  margin-left: 2% !important;
}

.list__btn--soldout {
  font-size: 12px;
  color: white !important;
  border: 2px solid black !important;
  background-color: #cb2431 !important;
  opacity: 1 !important;
  cursor: not-allowed !important;
}

.list__btn--soldout:hover {
  color: white !important;
  border: 2px solid black !important;
  background-color: #cb2431 !important;
  opacity: 1 !important;
}



.list__btn--soldout {
  font-size: 12px;
} */
