@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/museo/museosanscyrl-300.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/museo/ofont.ru_Museo Sans Cyrl 300.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/museo/ofont.ru_Museo Sans Cyrl 500.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/museo/ofont.ru_Museo Sans Cyrl 700.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/museo/ofont.ru_Museo Sans Cyrl 900.ttf') format('truetype'), /* Safari, Android, iOS */
}


html, body {
  margin: 0;
  padding: 0;
  font-family: 'Museo Sans', 'Helvetica', sans-serif;
  background-color: var(--dark-grey);
  /* background-image: url('https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/2022%2Fpsyshit-photo.png?alt=media&token=1ed9883c-c448-47c9-8c4e-37d55a5012e2'); */
  /* background-size: cover; */
  color: white;
}

#root {

}

html,
body,
#root,
.app {
  width: 100%;
  height: 100%;
}

.app {
  height: 100%;
  width: 100%;
}

.main-container {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000000;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/psyshit-999.appspot.com/o/2022%2Fpsyshitbg.gif?alt=media&token=5d16e2bc-f0ed-40ed-a4dc-dd0e27c83a1c');
}

@media all and (orientation:portrait) {

}

@media all and (orientation:landscape) {

}



@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fade {
  from {
    transform: translateY(.25em);
    opacity: 0
  }
}
