.video-bg {

}

.video-bg__video-overlay,
.video-bg__video {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.video-bg__video-overlay {
  width: 150%;
  height: 150%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.8);
  /* opacity: 0.5; */
}

.video-bg__video {
  z-index: 8;
  /* background-image: url('/pm.jpg'); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-position: center center; */
  transition: 1s opacity;
}
