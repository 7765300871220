.footer {
  position: relative;
  z-index: 20;
  padding-bottom: 45px;
  text-align: center;
  font-family: 'Museo Sans', 'Helvetica', sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 1.4;
  user-select: none;
}
